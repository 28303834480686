<template>
    <v-container fluid>
        <v-form @submit.prevent="getPriceSubscriptions('search')">
            <v-btn v-show="$vuetify.breakpoint.xsOnly" :to="{name: 'price_subscriptions.create'}" color="indigo" dark fab fixed top
                   right class="v-btn--add-form-top">
                <v-icon>mdi-plus</v-icon>
            </v-btn>
            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                <v-card flat>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" sm="10">
                                <v-subheader class="headline">{{ $t('search_price_subscription') }}</v-subheader>
                            </v-col>
                            <v-col v-if="!$vuetify.breakpoint.xsOnly" cols="12" sm="2" class="text-right">
                                <v-btn :to="{name: 'price_subscription.create'}" dark color="indigo">{{ $t('create') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="py-0" cols="12" sm="6">
                                <ValidationProvider ref="time_subscription"
                                                    v-slot="{ errors, valid }">
                                    <v-select v-model="time_subscription" :items="itemTimeSubscription"
                                              :error-messages="errors" :disabled="loadingTimeSubscription" item-text="name"
                                              item-value="id" return-object
                                              prepend-icon="mdi-timetable" :label="$t('time_subscription')"
                                              clearable></v-select>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="6">
                                <ValidationProvider ref="subscription_name"
                                                    v-slot="{ errors, valid }">
                                    <v-select v-model="subscription_name" :items="itemNameSubscription"
                                              :error-messages="errors" :disabled="loadingNameSubscription" item-text="name"
                                              item-value="id" return-object
                                              prepend-icon="mdi-clipboard-account-outline" :label="$t('subscription_name')"
                                              clearable></v-select>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="6">
                                <ValidationProvider ref="subscription_property"
                                                    v-slot="{ errors, valid }">
                                    <v-select v-model="subscription_property" :items="itemSubscriptionProperty"
                                              :error-messages="errors" :disabled="loadingSubscriptionProperty" item-text="name"
                                              item-value="id" return-object
                                              prepend-icon="mdi-account-child" :label="$t('subscription_property')"
                                              clearable></v-select>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="6">
                                <ValidationProvider ref="type_subscription"
                                                    v-slot="{ errors, valid }">
                                    <v-select v-model="type_subscription" :items="itemTypeSubscription"
                                              :error-messages="errors" :disabled="loadingTypeSubscription" item-text="name"
                                              item-value="id" return-object
                                              prepend-icon="mdi-id-card" :label="$t('type_subscription')"
                                              clearable></v-select>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="6">
                                <v-select v-model="active" :items="activeItems" :disabled="loading"
                                          item-text="name" item-value="id" prepend-icon="mdi-check"
                                          :label="$t('active')"></v-select>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="6">
                                <v-select :value="perPage" :items="perPageItems" :disabled="loading"
                                          :label="$t('items_per_page')" prepend-icon="mdi-counter"
                                          @input="perPage = options.itemsPerPage = Number($event)" hide-details
                                          ></v-select>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="px-4">
                        <v-row>

                            <v-col cols="12" sm="12" md="12"
                                   class="d-flex justify-center justify-sm-end align-self-center">
                                <v-btn type="submit" :disabled="invalid || loading" :block="$vuetify.breakpoint.xsOnly"
                                       color="primary">{{ $t('search') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </ValidationObserver>
        </v-form>
        <v-card flat>
            <v-card-text>
                <v-data-table :headers="headers" :items="price_subscriptionsItems" :options.sync="options" :page.sync="page"
                              :items-per-page="perPage" :server-items-length="totalPriceSubscriptions" :sort-by.sync="sortBy"
                              :sort-desc.sync="sortDir" :loading="loading" :locale="lang"
                              :loading-text="$t('loading_please_wait')" class="elevation-1"
                              hide-default-footer>
                    <template v-slot:item.time_subscription="{ item }">
                        <div :class="item.deleted ? 'red--text' : ''">
                            {{ item.time_subscription }}
                            {{ item.deleted ? ' (' + $t('deleted') + ')' : '' }}
                        </div>
                    </template>
                    <template v-slot:item.active="{ item }">
                        {{ item.active ? $t('yes') : $t('no')}}
                    </template>
                    <template v-slot:item.time_from="{ item }">
                        {{  item.time_from ? $moment(item.time_from).format('HH:mm') : ''}}
                    </template>
                    <template v-slot:item.time_to="{ item }">
                        {{  item.time_to ? $moment(item.time_to).format('HH:mm') : ''}}
                    </template>
                    <template v-slot:item.action="{ item }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on" :disabled="!can(['administrator'])"
                                       @click="editPriceSubscription(item)" icon>
                                    <v-icon>mdi-square-edit-outline</v-icon>
                                </v-btn>
                            </template>
                            <span>{{$t('edit')}}</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn icon v-on="on" :disabled="item.deleted" @click="deletePriceSubscription(item)">
                                    <v-icon>mdi-delete-outline</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ item.deleted ? $t('remotely') : $t('delete') }}</span>
                        </v-tooltip>
                    </template>
                </v-data-table>
            </v-card-text>
            <v-card-actions class="justify-center">
                <v-pagination v-model="page" :total-visible="totalVisiblePag"
                              :length="pageCount" :disabled="loading"></v-pagination>
            </v-card-actions>
        </v-card>
    </v-container>
</template>
<script>

import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {mapGetters} from "vuex";

export default {
    name: "PriceSubscriptions",
    components: {
        ValidationProvider,
        ValidationObserver
    },
    data() {
        return {
            sortBy: "id",
            sortDir: true,
            loading: false,
            loadingTimeSubscription: false,
            loadingTypeSubscription: false,
            subscription_name: null,
            itemNameSubscription: [],
            loadingNameSubscription: false,
            subscription_property: null,
            itemSubscriptionProperty: [],
            loadingSubscriptionProperty: false,
            time_subscription: null,
            itemTimeSubscription: [],
            type_subscription: null,
            itemTypeSubscription: [],
            options: {},
            page: 1,
            pageCount: 0,
            perPage: 5,
            totalVisiblePag: 5,
            totalPriceSubscriptions: 0,
            price_subscription: null,
            price_subscriptionsItems: [],
            active: 1,
            activeItems: [
                {
                    id: null,
                    name: this.$t('all')
                },
                {
                    id: 1,
                    name: this.$t('yes')
                },
                {
                    id: 0,
                    name: this.$t('no')
                }
            ],
            headers: [
                {
                    text: this.$t('time_subscription'),
                    align: "left",
                    sortable: false,
                    value: "time_subscription"
                },
                {
                    text: this.$t('subscription_name'),
                    align: "left",
                    sortable: false,
                    value: "subscription_name"
                }, {
                    text: this.$t('subscription_property'),
                    align: "left",
                    sortable: false,
                    value: "subscription_property"
                },
                {
                    text: this.$t('type_subscription'),
                    align: "left",
                    sortable: false,
                    value: "type_subscription"
                },
                {
                    text: this.$t('visit_time'),
                    align: "left",
                    sortable: false,
                    value: "visit_time"
                },
                {
                    text: this.$t('price'),
                    align: "left",
                    sortable: false,
                    value: "price"
                },
                {
                    text: this.$t('active'),
                    align: "center",
                    sortable: false,
                    value: "active"
                },

                {
                    text: this.$t('actions'),
                    align: "center",
                    value: 'action',
                    sortable: false,
                    width: 120,
                },
            ],
            first_download: 1,
        };
    },
    computed: {
        ...mapGetters(['lang', 'itemsPerPage', 'perPageItems']),

    },
    async mounted() {
        this.options.itemsPerPage = this.perPage = this.itemsPerPage
        await this.getTypeSubscription()
        await this.getTimeSubscription()
        await this.getNameSubscription()
        await this.getSubscriptionProperty()
    },
    watch: {
        options: {
            handler() {
                  this.getPriceSubscriptions()
             },
            deep: false
        },
    },
    methods: {
        editPriceSubscription(item) {
            this.$router.push({
                name: 'price_subscription.edit',
                params: {
                    id: item.id
                }
            })
        },
        async getPriceSubscriptions(type) {
            this.loading = true;
            const {
                sortBy,
                sortDesc,
                page,
                itemsPerPage
            } = this.options;
            let params = {};
            if (sortBy[0] !== undefined) {
                params.sortBy = sortBy[0];
            }
            if (sortDesc[0] !== undefined) {
                params.sortDir = sortDesc[0] ? 'asc' : 'desc';
            }
            if (page !== undefined) {
                if (type === 'search') {
                    params.page = 1
                } else {
                    params.page = page
                }
            }
            if (itemsPerPage !== undefined) {
                params.perPage = itemsPerPage;
            }

            params.active = this.active

            if (this.price_subscription) {
                params.price_subscription = this.price_subscription
            }
            if (this.time_subscription) {
                if (this.time_subscription.id) {
                    params.time_subscription =  this.time_subscription.id
                } else {
                    params.time_subscription =  this.time_subscription
                }
            }
            if (this.type_subscription) {
                if (this.type_subscription.id) {
                    params.type_subscription =  this.type_subscription.id
                } else {
                    params.type_subscription =  this.type_subscription
                }
            }
            if (this.subscription_name) {
                if (this.subscription_name.id) {
                    params.subscription_name =  this.subscription_name.id
                } else {
                    params.subscription_name =  this.subscription_name
                }
            }
            if (this.subscription_property) {
                if (this.subscription_property.id) {
                    params.subscription_property =  this.subscription_property.id
                } else {
                    params.subscription_property =  this.subscription_property
                }
            }

            await this.$http
                .get("admin/price_subscription", {
                    params: params,
                })
                .then(res => {
                    this.price_subscriptionsItems = res.body.data
                    this.page = res.body.meta.current_page
                    this.totalPriceSubscriptions = res.body.meta.total
                    this.pageCount = res.body.meta.last_page
                })
                .catch(err => {
                    this.price_subscriptionsItems = []
                    this.totalPriceSubscriptions = 0
                    this.$toastr.error(this.$t('failed_to_get_list_price_subscriptions'))
                })
                .finally(end => {
                    this.loading = false
                })
        },
        async deletePriceSubscription(item) {
            if (confirm(this.$t('delete_price_subscription'))) {
                var _this = this
                this.loading = true;
                await this.$http
                    .delete(`admin/price_subscription/${item.id}`)
                    .then(res => {
                        this.$toastr.success(this.$t('price_subscription_has_been_deleted'))
                        this.getPriceSubscriptions()
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('price_subscription_has_not_been_deleted'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.loading = false
                    })
            }
        },
        async getTimeSubscription() {
            this.loadingTimeSubscription = true;
            let params = {}
            if (this.language) {
                params.language = this.language
            }
            params.filter =  "search";
            await this.$http
                .get(`admin/time_subscription/`, {params: params})
                .then(res => {
                    this.itemTimeSubscription = res.body.data
                })
                .catch(err => {
                    this.$toastr.error(this.$t('messages.failed_to_get_list_time_subscriptions'));
                    this.itemTimeSubscription = []

                })
                .finally(end => {
                    this.loadingTimeSubscription = false
                });
        },
        async getNameSubscription() {
            this.loadingNameSubscription = true;
            let params = {}
            if (this.language) {
                params.language = this.language
            }
            params.filter =  "search";
            await this.$http
                .get(`admin/subscription_name/`, {params: params})
                .then(res => {
                    this.itemNameSubscription = res.body.data
                })
                .catch(err => {
                    this.$toastr.error(this.$t('messages.failed_to_get_list_name_subscriptions'));
                    this.itemNameSubscription = []

                })
                .finally(end => {
                    this.loadingNameSubscription = false
                });
        },
        async getSubscriptionProperty() {
            this.loadingSubscriptionProperty = true;
            let params = {}
            if (this.language) {
                params.language = this.language
            }
            params.filter =  "search";
            await this.$http
                .get(`admin/subscription_property/`, {params: params})
                .then(res => {
                    this.itemSubscriptionProperty = res.body.data
                })
                .catch(err => {
                    this.$toastr.error(this.$t('messages.failed_to_get_list_subscription_properties'));
                    this.itemSubscriptionProperty = []

                })
                .finally(end => {
                    this.loadingSubscriptionProperty = false
                });
        },
        async getTypeSubscription() {
            this.loadingTypeSubscription = true;
            let params = {}
            if (this.language) {
                params.language = this.language
            }
            params.filter =  "select";
            await this.$http
                .get(`admin/type_subscription/`, {params: params})
                .then(res => {
                    this.itemTypeSubscription = res.body.data
                })
                .catch(err => {
                    this.$toastr.error(this.$t('messages.failed_to_get_list_type_subscriptions'));
                    this.itemTypeSubscription = []

                })
                .finally(end => {
                    this.loadingTypeSubscription = false
                });
        },

    }
}
</script>
